import React from 'react'

import Text from '../Text'
import Button from '../Button'

const SplashPage = ({
  headline,
  subheadline,
  text,
  link,
  cta,
  button
}) => {
  return (
    <div className='fb-splash-page'>
      <div className='fb-splash-page__content'>
        {headline && (
          <h1 className='fb-splash-page__heading'>
            {headline}
          </h1>
        )}
        {subheadline && (
          <Text size='xxlarge' weight='bold' className='fb-splash-page__subheading'>
            {subheadline}
          </Text>
        )}
        {text && (
          <Text tag='p' className='fb-splash-page__text'>
            {text}
          </Text>
        )}
        {link && (
          <div className='fb-splash-page__link'>
            {link}
          </div>
        )}
        {cta && (
          <Text size='xxlarge' weight='bold' className='fb-splash-page__cta'>
            {cta}
          </Text>
        )}
        {button && (
          <Button color='accent' mailto={button[1]} className='fb-splash-page__button'>{button[0]}</Button>
        )}
      </div>
    </div>
  )
}

export default SplashPage

import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Page from '../components/Page'

import SplashPage from '../components/SplashPage'

class RootIndex extends React.Component {
  render () {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Page>
        <Helmet title={siteTitle} />
        <SplashPage
          headline='Powerful, intuitive, offline-capable'
          subheadline='Supply chain management and analytics'
          text='Field Supply is a fully-featured, enterprise-grade LMIS; designed with an
           intimate understanding of the unique challenges faced by people working in frontier health
           systems. Field Supply represents a new kind of enterprise software: one which is designed from the ground up
           to support the women and men that public health systems depend on for health outcomes, critical data, and
           ultimate success.'
          link={<a href='/downloads/Field-Supply-Product-Brochure-2021.pdf'>See product brochure</a>}
          cta='Book a demo today'
          button={['Contact us', 'hello@field.supply']}
        />
      </Page>
    )
  }
}

export default RootIndex

// Note: to filter by a specific entry id, for some reason you need to prepend the string with "c"
// e.g. for 3L7GNQzBNe80sqQCEYE0my you'll have to use c3L7GNQzBNe80sqQCEYE0my in the query

export const pageQuery = graphql`
  query FSSplashQuery {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`
